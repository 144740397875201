import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"

function SupportDetail() {
  return (
    <Layout>
      <Seo title="Support Detail" />
      <InnerHero title="Support Detail"/>
      <section class="pt-120 pb-120 position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-header text-center">
                <span class="section-sub-title">GOT A QUESTION?</span>
                <h2 class="section-title">We&apos;ve got answers.</h2>
                <p>
                  If you have a question or general enquiry, we&rsquo;re here to
                  help. Complete the form below and we will get back to you as
                  soon as we can.
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-none-40 cmn-accordion">
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <div class="card-header py-3">
                  <h3>Account</h3>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <span class="faq-sub-list-title">
                        Recover Your Account
                      </span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">I lost my account!</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Recover your NoSurrender ID
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        How can I keep my account safe?
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        Someone else is playing my account!
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">NoSurrender ID</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Recover your NoSurrender ID
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">About NoSurrender ID</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Help with Verification Codes
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Save your account with NoSurrender ID
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Playing with multiple game accounts
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        How to opt-out of marketing messages
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">
                        Access/Delete Your Data
                      </span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">About GDPR</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Request your personal data</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Request deletion of your personal data
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <div class="card-header py-3">
                  <h3>Purchases</h3>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <span class="faq-sub-list-title">Missing Purchase</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        I didn't get my Android purchase!
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        I didn't get my iOS purchase!
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        I can't buy offers!
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">In-App Purchases</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Personalised Shop</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Add, remove, and edit payment methods on Android
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Add, edit, and remove payment methods on iOS
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Preventing and managing in-app purchases
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Why is my Gems/Diamonds balance negative?
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        Where do I find my purchase history?
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">NoSurrender Store</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        What is the NoSurrender Store?
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Purchasing a Gold Pass</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Activating a Gold Pass</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Managing your Gold Passes</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Donating Gold Passes</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Help with store refunds</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Finding your store receipt</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Help with store purchases</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <div class="card-header py-3">
                  <h3>Gameplay</h3>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <a href="/coming-soon.html">bla bla gameplay subject</a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        bla bla gameplay subject
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">Settings</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        How to Change Your Username
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        How to Disable Notifications
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Maintenance Breaks</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Friends &amp; the Social tab
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <div class="card-header py-3">
                  <h3>Troubleshooting</h3>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <span class="faq-sub-list-title">Technical Issues</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">How to Start a New Game</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Unstable Connection</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        How do I reinstall the game?
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Sending Bug Reports</a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        My game crashes!
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">Feedback</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Sending Feedback</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Contact Form</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <div class="card-header py-3">
                  <h3>Fair &amp; Safeplay</h3>
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      <span class="faq-sub-list-title">Banned Accounts</span>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        Why was I banned?
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">Code of Conduct</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Someone else is playing my account!
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Don't be a cheat!</a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Cheaper Gems/Diamonds are a scam
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        A bought account is never yours
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Phishing for accounts is not okay!
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        NoSurrender and Third Party Licenses
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        List of Unaccepted Behaviour
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        Sharing your account is never okay!
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">Taking Action</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        Reporting an offensive player
                      </a>
                    </li>
                    <li>
                      <a class="mb-2" href="/coming-soon.html">
                        What does Supercell do to employ Fair Play?
                      </a>
                    </li>
                    <li>
                      <span class="faq-sub-list-title">For Parents</span>
                    </li>
                    <li>
                      <a href="/coming-soon.html">
                        What is Safe and Fair Play?
                      </a>
                    </li>
                    <li>
                      <a href="/coming-soon.html">Parent's Guide</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SupportDetail
